import React from 'react'
import './Home.css';
import Navbar from '../../Components/NavBar/Navbar';
import Header from '../../Components/Header/Header';
import Featured from '../../Components/feature/Featured';
import PropertyList from '../../Components/propertyList/property';
import FeaturedProperties from '../../Components/FeaturedProperties/FeaturedProperties';
import MailList from '../../Components/MailList/MailList';
import Footer from '../../Components/Footer/Footer';

const Home = () => {
  return (
    <div>
      <Navbar/>
      <Header/>
      <div className="homeContainer">
        <Featured/>
        <h1 className='homeTitle'>Property Type</h1>
        <PropertyList/>
        <h1 className='homeTitle'>Home Guests</h1>
        <FeaturedProperties/>
        <MailList/>
        <Footer/>
        </div>
    </div>
  )
}

export default Home